import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',

    /**
     *     key: window.appConfig.reverbAppKey,
     *     wsHost: window.appConfig.wsHost,
     *     wsPort: window.appConfig.wsPort,
     *     wssPort: window.appConfig.wssPort,
     *     forceTLS: window.appConfig.wssForceTLS,
     */

    key: window.appConfig.reverbAppKey,
    wsHost: window.appConfig.wsHost,
    wsPort: window.appConfig.wsPort,
    wssPort: window.appConfig.wssPort,
    forceTLS: window.appConfig.wssForceTLS,
    enabledTransports: ['ws', 'wss'],
});

window.Echo.connector.pusher.connection.bind('connected', () => {
    console.log('WebSocket connected');
});

window.Echo.connector.pusher.connection.bind('error', (error) => {
    console.error('Соединение потеряно:', error);
});

export default {
    privateListen: function (channelName, channelId, callBack) {
        console.log('Subscribed: ' + channelName + '.' + channelId);
        window.Echo.private(channelName + '.' + channelId)
            .listen('.' + channelName, callBack);
    },

    privateListenOff: function (channelName, channelId) {
        console.log('Unsubscribed: ' + channelName + '.' + channelId);
        window.Echo.private(channelName + '.' + channelId)
            .stopListening('.' + channelName);
    },
}
